import { useTheme } from "@emotion/react";
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Container,
  IconButton,
  InputBase,
  Typography,
  useMediaQuery,
  Button as ButtonMaterial,
  Tabs,
  Tab,
  TabScrollButton,
  Badge,
  TextField,
  InputAdornment,
  Divider,
  Radio
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from '@mui/system';

import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// API
import { getAllTypeAttrsByTypeId } from "../../../../api/typeAttrs/getAllTypeAttrs";
import { getCategoryByNameAndType } from "../../../../api/categories/getCategoryByName";
import {
  getProductListings,
  getProductListingsFilters,
} from "../../../../api/product/getListingsByProductType";

import styles from "./styles.module.css";
import { useTranslate } from "../../../../context/translate/translate.context";

// Components
import CategorySeo from "../../../../lib/seo/CategorySeo";
import BreadcrumbsNavigation from "../../../BreadcrumbsNavigation";
import ProductCard from "../../../ProductCard";
import Button from "../../../UI/Button";
import ArrowLeft from "../../../svg/ArrowLeft";
import CupIcon from "../../../svg/Cup";
import SearchIcon from "../../../svg/SearchIcon";
import { MentupSwitch } from "../../../../lib/theme";
import MentupModal from "../../../MentupModal/MentupModal";

// Icons
import FiltersSVG from "../../../../lib/svg/filters";
import ArrowDownSVG from "../../../../lib/svg/arrowDown";
import { ClearIcon } from "@mui/x-date-pickers";
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import RemoveIcon from '@mui/icons-material/Remove';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import PercentIcon from '@mui/icons-material/Percent';
import StarIcon from '@mui/icons-material/Star';

const defaultFilters = {
  limit: 8,
  country: "RU",
  text: "",
  sort: { updatedAt: -1 },
};

// Custom styled scroll button component
const CustomScrollButton = styled(TabScrollButton)(({ theme, direction }) => ({
  '&.MuiTabScrollButton-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mode === 'dark' ? '#26262C' : '#8142f64d', // Dark background
    borderRadius: '50%', // Circle shape
    width: '40px',
    height: '40px',
    marginLeft: '10px',
    // Hide scroll button when not in use
    
     // Apply styles based on direction
    ...(direction === 'left' ? {
      left: '0',
      '&.Mui-disabled': {
        display: 'none', // Completely remove the button from layout
      },
      marginRight: '10px', // Offset for visibility
    } : {
      right: '0',
      '&.Mui-disabled': {
        display: 'none', // Completely remove the button from layout
      },
      marginRight: '10px', // Offset for visibility
    }),
    // Hide scroll button on mobile
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide the button on small screens
    },
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'dark' ? '#fff' : 'black', // White arrow color
  },
}));

const CustomScrollButtonAttrs = styled(TabScrollButton)(({ theme, direction }) => ({
  '&.MuiTabScrollButton-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mode === 'dark' ? '#26262C' : '#8142f64d', // Dark background
    borderRadius: '50%', // Circle shape
    width: '40px',
    height: '40px',
    marginLeft: '10px',
    paddingY: '0',
    // Hide scroll button when not in use
    
     // Apply styles based on direction
    ...(direction === 'left' ? {
      left: '0',
      '&.Mui-disabled': {
        display: 'none', // Completely remove the button from layout
      },
      marginRight: '10px', // Offset for visibility
    } : {
      right: '0',
      '&.Mui-disabled': {
        display: 'none', // Completely remove the button from layout
      },
      marginRight: '10px', // Offset for visibility
    }),
    // Hide scroll button on mobile
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide the button on small screens
    },
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'dark' ? '#fff' : 'black', // White arrow color
  },
}));

const CategoryProductPage = () => {
  const { slug, typeSlug } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const ref = useRef(null);
  const buttonRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [category, setCategory] = useState(null);
  const [activeType, setActiveType] = useState(null);
  const [loadingCategory, setLoadingCategory] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [products, setProducts] = useState([]); // Initial empty Array
  const [pages, setTotalPages] = useState(0);
  const [allFilters, setAllFilters] = useState();
  const [priceRadius, setPriceRadius] = useState([0, 0]);
  const [filters, setFilters] = useState(defaultFilters);
  const [attrs, setAttrs] = useState([]);
  const [activeAttr, setActiveAttr] = useState(null);
  const [openGroup, setOpenGroup] = useState(false);
  const [activeGroup, setActiveGroup] = useState(null);
  const [selectedAttr, setSelectedAttr] = useState([]);
  const [groups, setGroups] = useState([]);
  const [originalGroups, setOriginalGroups] = useState([]);

  const queryToArray = () =>{
    // Get the query string from the URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let paramsArray = [];
  
    // Iterate through the query parameters and create objects
    urlParams.forEach((value, key) => {
      let parsedValue = value;
      try {
        parsedValue = JSON.parse(value);
      } catch (e) {
        // If it's not valid JSON, leave the value as a string
      }
  
      paramsArray.push({
        key,
        value: parsedValue,
      });
    });
    return paramsArray
  }

  useEffect(() => {

    let paramsArray = queryToArray();  

    // Set Params to useState
    if (paramsArray.length > 0 && activeType && slug && category && attrs.length > 0 && originalGroups.length > 0) {
      // Find matching attributes
      const arr = JSON.parse(JSON.stringify(attrs));
      const matchingAttributesKey = arr.filter(attribute =>
        paramsArray.some(param => param.key === attribute?.slug)
      );
  
      const matchingGroupsKey = originalGroups.filter(group =>
        paramsArray.some(param => param.key === group?.slug)
      );
  
      // Find matching attributes from the group
      const matchingAttributesFromGroup = matchingGroupsKey.map(group => {
        return group.attributes.filter(a =>
          paramsArray.some(param => {
            if (a.attributeGroup.groupType === 'single') {
              if (typeof param.value === 'string') {
                // Split string into an array of individual values, trim whitespace, and compare exactly
                const values = param.value.split(',').map(v => v.trim());
                return values.some(value => value === a.slug); // Exact match
              } else if (Array.isArray(param.value)) {
                // Compare each array item for an exact match
                return param.value.some(value => value === a.slug);
              }
            }
            if (a.attributeGroup.groupType === 'switch') {
              return param.value === a.value;
            }
            if (a.attributeGroup.groupType === 'range') {
              return param.key.includes(a.slug);
            }
            return false;
          })
        );
      });
  
      // Combine all matching attributes
      const allAttrs = [
        ...matchingAttributesKey,
        ...matchingAttributesFromGroup
      ].flat();

      // remove dublicate entries in the array of obejcts allAttrs
      const uniqueAttrs = allAttrs.filter((value, index, self) => 
        index === self.findIndex((t) => (
          t._id === value._id // Replace 'id' with the unique property of your objects
        ))
      );

      // Handle each attribute based on its groupType
      uniqueAttrs.forEach((attr) => {
        
        if (attr.attributeGroup?.groupType === 'single') {
          handleAttrClick(attr);
        } else if (attr.attributeGroup?.groupType === 'range') {
          // Ensure range values are being passed as min and max correctly
          const rangeParam = paramsArray.find((p) => p.key === attr.attributeGroup.slug);
          if (rangeParam) {
            handleInnerRange(attr, Number(rangeParam.value[0]), false); // min
            handleInnerRange(attr, rangeParam.value[1], true); // max
          }
        } else if (attr.attributeGroup?.groupType === 'switch') {
          handleSwitch(originalGroups.find((g) => g.slug === attr.slug), false);
        } else if (attr.attributeGroup?.groupType === 'choice') {
          handleChoice(attr);
        }
      });

      // Update range values in matching attributes
      uniqueAttrs.forEach((attr) => {
        paramsArray.forEach((param) => {
          if (attr.attributeGroup.slug === param.key && Array.isArray(param.value) && param.value.length === 2) {
            attr.valueRange.min = param.value[0]; // Update valueRange.min with param.value[0]
            attr.valueRange.max = param.value[1]; // Update valueRange.max with param.value[1]
          }
        });
      });
  
      // Ensure all attributes are processed
      setTimeout(() => handleActiveAttrChange(uniqueAttrs, true), 0);
    }
    
  }, [attrs, originalGroups, activeType, slug, category]);
  
  // Function to handle search
  const handleSearch = useCallback(
    async (f) => {
      if (!activeType?._id) return; // Ensure activeType is set
      if(!category?._id) return;
      setLoadingProducts(true);
      f.attribute = activeAttr?._id;
      f.category = category?._id
      f.q = queryToArray()
      const { data } = await getProductListings(f);
      setProducts((prev) => (f.page ? prev.concat(data.items) : data.items));
      setTotalPages(data.totalPages);
      setLoadingProducts(false);
    },
    [filters.sort, activeType?._id, activeAttr?._id]
  );
  
  // Debounce the search function
  const debouncedSearchText = useMemo(() => debounce(handleSearch, 400), [handleSearch]);
  
  // Function to check the sort
  const checkSort = useCallback(
    async (sort) => {
      setCurrentPage(0);
      setFilters((prev) => ({ ...prev, sort: { price: sort, updatedAt: -1} }));
    },
    [filters.sort]
  );
  
  // Function to get the filters
  const getFilters = async (category, type) => {
    try {
      const { data } = await getProductListingsFilters(category, type);
      setAllFilters(data);
      setPriceRadius([data.price.min, data.price.max]);
      setFilters((prev) => ({
        ...prev
      }));
    } catch (error) {
      navigate('/404')
    }
  };

// Function to get the type attributes
const getTypeAttrs = async (typeId, category) => {
  try {
    const { data } = await getAllTypeAttrsByTypeId(typeId, category._id);

    const grouped = {};

    data.forEach((attr, index) => {
      const groupId = attr.attributeGroup?._id;
      if (groupId) {
        if (!grouped[groupId]) {
          grouped[groupId] = {
            ...attr.attributeGroup,
            attributes: []
          };
        }
        grouped[groupId].attributes.push(attr);
      }
    });

    const organizedGroups = Object.values(grouped);

    // Add "all" group as the first entry

    const groups = [
      {
        _id: '0',
        name: 'Все',
        slug: 'all',
        disabled: false,
        groupType: 'all',
      },
      ...organizedGroups,
      {
        _id: "1",
        name: 'Цена',
        slug: 'price',
        disabled: false,
        groupType: 'range',
        attributes: [{
          attributeGroup: {
            _id: '1',
            name: 'Цена',
            slug: 'price',
            disabled: false,
            groupType: 'range',
          },
          category: category._id,
          name: 'Цена',
          productType: typeId,
          slug: 'price',
          valueRange: {
            min: priceRadius[0] || 0,
            max: priceRadius[1] || 100
          },
          _id: '1'
        }]
      },
      {
        _id: '2',
        name: 'Скидка',
        slug: 'discount',
        disabled: false,
        groupType: 'switch',
        attributes: [{
          attributeGroup: {
            _id: '2',
            name: 'Скидка',
            slug: 'discount',
            disabled: false,
            groupType: 'switch',
          },
          category: category._id,
          name: 'Скидка',
          productType: typeId,
          value: false,
          slug: 'discount',
          _id: '2'
        }]
      },
      {
        _id: '3',
        name: 'Отзывы',
        slug: 'reviews',
        disabled: false,
        groupType: 'switch',
        attributes: [{
          attributeGroup: {
            _id: '3',
            name: 'Отзывы',
            slug: 'reviews',
            disabled: false,
            groupType: 'switch',
          },
          category: category._id,
          name: 'Отзывы',
          productType: typeId,
          value: false,
          slug: 'reviews',
          _id: '3'
        }]
      } 
    ]
    setGroups(groups);

    setOriginalGroups(groups)

    // remove the entries that dont have a groupType from data
    data.forEach((attr, index) => {
      if (!attr.attributeGroup) {
        data.splice(index, 1);
      }
    });

    // Update attrs state with "all" entry
    setAttrs([
      {
        category: category._id,
        name: 'Все',
        productType: typeId,
        slug: 'all',
        _id: '0'
      },
      ...data,
      {
        attributeGroup: {
          _id: '1',
          name: 'Цена',
          slug: 'price',
          disabled: false,
          groupType: 'range',
        },
        category: category._id,
        name: 'Цена',
        productType: typeId,
        slug: 'price',
        valueRange: {
          min: priceRadius[0] || 0,
          max: priceRadius[1] || 100
        },
        _id: '1'
      },
      {
        attributeGroup: {
          _id: '2',
          name: 'Скидка',
          slug: 'discount',
          disabled: false,
          groupType: 'switch',
        },
        category: category._id,
        name: 'Скидка',
        productType: typeId,
        value: false,
        slug: 'discount',
        _id: '2'
      },
      {
        attributeGroup: {
          _id: '3',
          name: 'Отзывы',
          slug: 'reviews',
          disabled: false,
          groupType: 'switch',
        },
        category: category._id,
        name: 'Отзывы',
        productType: typeId,
        value: false,
        slug: 'reviews',
        _id: '3'
      },
    ]);


    const type = category?.productTypes.find(({ _id }) => _id === typeId);
    if (slug && type.slug) {
      getFilters(category._id, typeId);
    }
  } catch (error) {
    navigate('/404')
  }
};

  // State to handle loading of next page
  const [loadingNextPage, setLoadingNextPage] = useState(false);

  // Function to handle next page
  const handleNextPage = useCallback(async () => {
    // Exit if already loading or if no more pages
    if (loadingProducts || loadingNextPage || currentPage + 1 >= pages) return;
  
    setLoadingNextPage(true); // Set loading state to true
    setCurrentPage(prevPage => {
      const newPage = prevPage + 1;
      debouncedSearchText({
        ...filters,
        type: activeType?._id,
        page: newPage,
      })
      setLoadingNextPage(false)
      return newPage;
    });
  }, [loadingProducts, loadingNextPage, currentPage, pages, filters, activeType?._id, debouncedSearchText]);
  
  // Function to handle scroll event
  const handleScroll = useCallback(() => {
    if (loadingProducts || loadingNextPage || currentPage + 1 >= pages) return;
  
    const rect = buttonRef.current?.getBoundingClientRect();
    if (rect && rect.top < window.innerHeight && rect.bottom >= 0) {
      handleNextPage();
    }
  }, [loadingProducts, loadingNextPage, currentPage, pages, handleNextPage]);

  // Debounce the scroll event
  const debouncedHandleScroll = useMemo(() => debounce(handleScroll, 300), [handleScroll]);
  
  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);
  
  // Function to get the category data
  const getCategory = async (slug) => {
    try {
      setLoadingCategory(true);
      const res = await getCategoryByNameAndType(slug);

      if (res.status === 200) {

        res.data?.productTypes?.sort((a, b) => {
          if (a.slug === 'prochee') {
            return 1;
          }
          if (b.slug === 'prochee') {
            return -1;
          }
          return 0; // Keep original order for everything else
        });
        
        setCategory(res.data);        

        const activeType = res.data.productTypes.find(
          ({ slug }) => slug === typeSlug
        );
        
        // Find the index of activeType in productTypes
        const activeTypeIndex = res.data.productTypes.findIndex(
          ({ slug }) => slug === typeSlug
        );
        
        // Set the activeType and its index if needed
        setActiveType(activeType);
        setValue(activeTypeIndex); // Assuming setValue is to set the index
        if (activeType?._id && res.data._id) {
          //getFilters(res.data._id, activeType._id);
          await getTypeAttrs(activeType._id, res.data);
        }
      }
    } catch (error) {
      navigate('/404')
    } finally {
      setLoadingCategory(false);
    }
  };

  // Fetch category data on component mount
  useEffect(() => {
    if (slug) {
      getCategory(slug);
    }
  }, [slug]);
  
  // Fetch products on category and type change
  useEffect(() => {
    if (activeType && allFilters) {
      debouncedSearchText({ ...filters, type: activeType._id });
    }
  }, [activeType, allFilters, filters]);

  const [groupsIncluded, setGroupsIncluded] = useState([]) 
  
  // Fetch products on attribute change and change the activeType, also reset the page
  const handleTypeClick = useCallback(
    async (type) => {
      setSelectedAttr([]);
      setGroupsIncluded([])
      setAttrValue(0);
      setFilters(defaultFilters)
      setProducts([]);
      setActiveType(type);
      setCurrentPage(0);
      setAttrs([]);
      setActiveAttr(null);
      
      // reset filters to default
      navigate(`/games/${slug}/${type.slug}`);
      if(type?._id && category?._id) {
        await getTypeAttrs(type._id, category);
      }
    },
    [category?._id, slug]
  );

  // Update handleActiveAttrChange function

  useEffect(() => {
    // Exit early if no changes are required
    const [first, ...rest] = groups;
  
    // Sort the rest of the groups
    const sortedRest = rest.sort((a, b) => {
      const aIncluded = groupsIncluded.some((g) => g._id === a._id);
      const bIncluded = groupsIncluded.some((g) => g._id === b._id);
  
      // If a group is not in selectedGroups, it should be in the position from originalGroups
      if (!aIncluded && bIncluded) return 1;
      if (aIncluded && !bIncluded) return -1;
  
      // If both are either in or not in selectedGroups, maintain their original order
      const originalIndexA = originalGroups.findIndex((g) => g._id === a._id);
      const originalIndexB = originalGroups.findIndex((g) => g._id === b._id);
  
      // Sort based on their positions in originalGroups
      return originalIndexA - originalIndexB;
    });
  
    const updatedGroups = [first, ...sortedRest];
  
    // Only update state if there is a meaningful change
    if (JSON.stringify(updatedGroups) !== JSON.stringify(groups)) {
      setGroups(updatedGroups);
    }
  }, [groupsIncluded, groups, originalGroups]);
  
  

  const handleActiveAttrChange = useCallback(
    (attr, urlExecute = false) => {
      // Resetting products and pagination
      setProducts([]);
      setCurrentPage(0);
  
      // Resetting attrValue if "all" is selected
      if (attr?.slug === 'all') {
        setAttrValue(0);
        setGroupsIncluded([])
        return
      }
  
      // Calculate unique attribute groups
      const uniqueGroups = Array.from(
        new Set(attr.map(attr => attr.attributeGroup))
      );
  
      // Ensuring uniqueness by _id
      const finalGroups = uniqueGroups.filter((group, index, self) =>
        index === self.findIndex((g) => g._id === group._id)
      );
      if(!urlExecute) {
      let query = '';

      // sort the groups array, check the groupsIncluded, and the ones included move at position 1 and above, if it is not, move back to its original position

      if (finalGroups.length > 0) {
        query = finalGroups
          .map((group) => {
            // Get all attributes belonging to this group
            const groupAttrs = attr.filter(attr => attr.attributeGroup._id === group._id);
            
            // Handle 'single' type attributes
            const singleAttrs = groupAttrs.filter(attr => attr.attributeGroup.groupType === 'single')
              .map(attr => attr.slug)
              .join(',');
            
            // Handle 'range' type attributes
            const rangeAttrs = groupAttrs.filter(attr => attr.attributeGroup.groupType === 'range')
              .map(attr => `[${attr.valueRange?.min || 0},${attr.valueRange.max  || 0}]`)  // Assuming minValue/maxValue are available for range attributes
              .join(',');

            const switchAttrs = groupAttrs.filter(attr => attr.attributeGroup.groupType === 'switch')
              .map(attr => `${attr.value}`)  // Assuming minValue/maxValue are available for range attributes
              .join(',');
            
            const choiceAttrs = groupAttrs.filter(attr => attr.attributeGroup.groupType === 'choice')
              .map(attr => `${attr.slug}=${attr.value}`)  // Assuming minValue/maxValue are available for range attributes
              .join(',');

            // Combine both single and range attributes for this group
            let groupQuery = '';
            if (singleAttrs) {
              groupQuery += `${group.slug}=${singleAttrs}`;
            }
            if (rangeAttrs) {
              if (groupQuery) groupQuery += '&'; // Add '&' if there's already a single type query
              groupQuery += `${group.slug}=${rangeAttrs}`;
            }
            if(switchAttrs) {
              if(groupQuery) groupQuery += '&';
              groupQuery += `${group.slug}=${switchAttrs}`;
            }
            if(choiceAttrs) {
              if(groupQuery) groupQuery += '&';
              groupQuery += `${choiceAttrs}`;
            }

            return groupQuery;
          })
          .join('&');
      }
  
      // Navigate to the new URL with query
      navigate(`/games/${slug}/${activeType.slug }${query ? `?${query}` : ''}`);
    }

      // Update groupsIncluded state if needed elsewhere
      setGroupsIncluded(finalGroups);
  
      // Trigger filters update
      getFilters(category._id, activeType._id);
  
      
    },
    [activeType?._id, category?._id, activeType?.slug, slug, selectedAttr, navigate, getFilters]
  );

  const handleCloseModal = () => {
    setOpenGroup(false);
    setActiveGroup(null);
  }

  const handleChoice = (attr) => {
    setSelectedAttr((prevState) => {
      // Filter out all attributes belonging to the same group as the selected attribute
      let updatedState = prevState.filter(
        (a) => a.attributeGroup._id !== attr.attributeGroup._id
      );
  
      // Check if the attribute already exists in the state
      const exists = prevState.some((a) => a._id === attr._id);
  
      if (!exists) {
        // Add the attribute with an initial value of true
        updatedState.push({
          ...attr,
          value: true,
        });
      }
  
      // Return the updated state
      return updatedState;
    });
  };
  

  const handleSwitch = (group, execute = true) => {
    let newState = [];
    
    setSelectedAttr((prevState) => {
      const updatedState = [...prevState];
      const selectedAttribute = group.attributes[0];
      const groupSlug = group.slug;
  
      const attrIndex = updatedState.findIndex((attr) => attr.slug === groupSlug);
  
      if (attrIndex !== -1) {
        // Remove attribute if value is true
        updatedState.splice(attrIndex, 1);
      } else {
        // Add new attribute
        updatedState.push({
          ...selectedAttribute,
          slug: groupSlug,
          value: true,
        });
      }
  
      // Store the updated state locally
      newState = updatedState;
      return updatedState;
    });
  
    // Ensure the state update is complete before calling handleActiveAttrChange
    if(execute) {
      setTimeout(() => {
        handleActiveAttrChange(newState);
      }, 0);
    }
  };
  
  // Update handleActiveGroup function
  const handleActiveGroup = useCallback(
    (group) => {
      if (group.groupType === 'range' || 
          group.groupType === 'single' || 
          group.groupType === 'all' ||
          group.groupType === 'choice'
        ) {
        setActiveGroup(group);
        setOpenGroup(true);
      } else if (group.groupType === 'switch') {
        handleSwitch(group)
      } else {
        handleActiveAttrChange(group);
      }
    },
    [activeType, category, handleActiveAttrChange]
  );

  // Render the info container
  const renderInfoContainer = ({ title, description }, index) => (
    <Box key={index} className={styles.infoItem}>
      <Typography variant="body1" className={styles.infoItemDescription}>
        {description}
      </Typography>
    </Box>
  );
  
  // Check if the user is on a mobile device
  const isMobile = useMediaQuery("@media (max-width: 900px)");

  // function to make every word in a string start with a capital letter
  const capitalize = (str) => {
    return str
      .toLowerCase() // Convert the entire string to lowercase first
      .split(' ') // Split the string into an array of words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a single string
  };

  const [value, setValue] = useState(0);

  const [attrValue, setAttrValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeAttr = (event, newValue) => {
    setAttrValue(newValue);
  }

  const handleInnerRange = (attr, value, isMax) => {
    setSelectedAttr((prevRanges) => {
      // Find the existing range for the attribute
      const existingRange = prevRanges.find((range) => range._id === attr._id);
  
      if (existingRange) {
        // Update the min or max value
        const updatedRanges = prevRanges.map((range) =>
          range._id === attr._id
            ? {
                ...range,
                valueRange: {
                  ...range.valueRange,
                  [isMax ? 'max' : 'min']: value, // Update min or max
                },
              }
            : range
        ) 
  
        // Check if both min and max are 0 or empty string
        const updatedRange = updatedRanges.find((range) => range._id === attr._id);
        const { min, max } = updatedRange.valueRange;
  
        if ((min === 0 || min === '' || min === null) && (max === 0 || max === '' || max === null)) {
          // Remove the range if both min and max are 0 or empty string
          const newRanges = updatedRanges.filter(
            (range) => !(range._id === attr._id && (range.valueRange.min === 0 || range.valueRange.min === '' || range.valueRange.min === null) && (range.valueRange.max === 0 || range.valueRange.max === '' || range.valueRange.max === null))
          );
  
          return newRanges;
        }
  
        return updatedRanges;
      } else {
        // Add a new range for the attribute if it doesn't exist
        const newRange = {
          ...attr,
          valueRange: {
            min: !isMax ? value : null,
            max: isMax ? value : null,
          },
        };
  
        // Don't add the new range if both values are 0 or empty string
        if ((newRange.valueRange.min === 0 || newRange.valueRange.min === '') && (newRange.valueRange.max === 0 || newRange.valueRange.max === '')) {
          return prevRanges;
        }
  
        return [...prevRanges, newRange];  // Properly add the new range by spreading the previous state
      }
    });
  };

  const handleAttrClick = (attr) => {
    setSelectedAttr((prev) => {
      let updatedAttr;
      if (prev.some((a) => a._id === attr._id)) {
        updatedAttr = prev.filter((a) => a._id !== attr._id);
      } else {
        updatedAttr = [...prev, attr];
      }  
      return updatedAttr;
    });
  };

  return (
    <Container className={styles.container}>
      {/* BreadCrumbs */}
      {category && activeType && (
        <BreadcrumbsNavigation
          breadcrumbs={[
            { label: "navigation.home", path: "/" },
            { label: capitalize(category.software[0].name), path: "/games"+'?s='+category.software[0].slug },
            { label: category.name, path: "/games/" + category.slug },
            { label: activeType?.name },
          ]}
        />
      )}

      {/* SEO */}
      {category && activeType && (
        <CategorySeo category={category} activeType={activeType} />
      )}

      {/* Category Types All List */}
      <Box className={styles.descriptionContainer}>
        <Typography variant="h1" className={styles.title}>
          {activeType?.name} {category?.name}
        </Typography>

        {/* Product Types */}

        {!!category?.productTypes?.length && (
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            components={{
              TabScrollButton: (props) => <CustomScrollButton {...props} direction={props.direction} />,
            }}
            ScrollButtonComponent={(props) => <CustomScrollButton {...props} direction={props.direction} />}
            sx={{ 
              '& .MuiTabs-indicator': { display: 'none' },
            }}
          >
            {category.productTypes.map((type, index) => (
              <Tab 
                key={index} 
                onClick={() => handleTypeClick(type)}
                label={type.name} 
              />
            ))}
          </Tabs>
        )}

        {/* Attributes */}

        {!!groups?.length && groups.length > 1 && (
          <Tabs
            value={attrValue}
            onChange={handleChangeAttr}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            components={{
              TabScrollButton: (props) => <CustomScrollButtonAttrs {...props} direction={props.direction} />,
            }}
            ScrollButtonComponent={(props) => <CustomScrollButtonAttrs {...props} direction={props.direction} />}
            sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
          >
            {groups.map((group, index) => (
              <Tab 
              key={index} 
              onClick={() => handleActiveGroup(group)}
              label={
                group.slug === 'all' ? (
                  <Badge 
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={groupsIncluded?.length || 0} 
                    sx={{
                      '& .MuiBadge-badge': {
                        background: (theme) => theme.palette.primary.yellow,
                      },
                      color: 'black'
                    }}
                  >
                    <TuneIcon 
                      sx={{
                        fontSize: '1.3em'
                      }} 
                      color="action" 
                    />
                  </Badge>
                ) : (
                  selectedAttr?.length > 0 && groupsIncluded.find((a) => a.slug === group.slug) && group?.groupType !== 'switch' ? (
                    `${selectedAttr.find((a) => a.attributeGroup._id === group._id)?.name || group.name} 
                    ${
                      selectedAttr.filter((a) => a.attributeGroup._id === group._id)?.length > 1 
                        ? `+${selectedAttr.filter((a) => a.attributeGroup._id === group._id)?.length - 1}` 
                        : ''
                    }`
                  ) : (
                    group?.groupType === 'switch' && group?.slug === 'discount' ? (
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.8
                      }}>
                        <Box
                          sx={{
                            background: (theme) => groupsIncluded.find((a) => a.slug === group.slug) ? theme.palette.primary.searchbg : theme.palette.primary.yellow,
                            display: 'flex',
                            alignItems: 'center',
                            py: 0.5,
                            px: 0.7,
                            borderRadius: '7px',
                            color: groupsIncluded.find((a) => a.slug === group.slug) ? "white" : 'black'
                          }}
                         >
                          <Typography sx={{
                            height: 'fit-content',
                            lineHeight: 0
                          }}>
                            -
                          </Typography>
                          <PercentIcon 
                            sx={{
                              fontSize: '0.8em',
                              verticalAlign: 'middle',
                            }} 
                          />
                        </Box>
                        {group?.name}
                      </Box>
                    ) : (group?.groupType === 'switch' && group?.slug === 'reviews') ? (
                      <>
                       <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.8
                      }}>
                        <StarIcon 
                            sx={{
                              fontSize: '1.2em',
                              verticalAlign: 'middle',
                              color: "primary.main",
                            }}    
                        />
                        {group?.name}
                      </Box>
                      </>
                    ) : (
                      group?.name
                    )
                  )
                )
              }
              
              sx={{
                background: (() => {
                  if (index === 0) {
                    return theme.palette.mode === 'dark' ? '#26262C !important' : '#8142f64d !important';
                  }
                  if (groupsIncluded.find((a) => a.slug === group.slug)) {
                    return '#EDF67D !important';
                  }
                  if (theme.palette.mode !== 'dark' && groupsIncluded.some((a) => a.slug !== group.slug)) {
                    return '#8142f64d !important';
                  }
                  if(theme.palette.mode !== 'dark' && selectedAttr.length === 0){
                    return '#8142f64d !important';
                  }
                  return '#26262C !important';
                })(),
                color: groupsIncluded.some((a) => a.slug === group.slug)
                  ? 'black !important'
                  : theme.palette.mode !== 'dark'
                  ? 'black !important'
                  : 'white !important',
              }}
              
            />
            ))}
          </Tabs>
        )}

      </Box>
      
      {/* Filters + Search */}
      <Box className={styles.filtersContainer}>

        <Box className={styles.inputContainer}>
          <InputBase
            ref={ref}
            className={styles.input}
            value={filters.text}
            onChange={({ target }) => {
              setCurrentPage(0);
              setFilters((prev) => ({ ...prev, text: target.value }));
            }}
            placeholder={translate("header.searchCategoryProduct")}
          />
          <IconButton
            className={styles.iconContainer}
            aria-label="clear search input"
            onClick={() => setFilters((prev) => ({ ...prev, text: "" }))}
            disabled={!filters.text}
          >
            {filters.text ? <ClearIcon /> : <SearchIcon />}
          </IconButton>
        </Box>

        <ButtonMaterial 
          color="secondary"
          className={styles.buttonMaterial}
          onClick={() => checkSort(filters.sort.price === -1 ? 0 : -1)}
        >
          <Typography sx={{
            fontSize: '14px'
          }}>
            {translate("vendorDashboard.filters.price.title")+ ' ' + (filters.sort.price === -1 ? translate("vendorDashboard.filters.price.asc") : translate("vendorDashboard.filters.price.desc"))}
          </Typography>
          <ArrowDownSVG
            color={theme.palette.mode === "dark" ? "#f6f6f6" : "#1f1d22"}
            isRotated={filters.sort.price === -1 ? true : false}
          />
        </ButtonMaterial>

        <Box className={styles.nowrap}>
          <FiltersSVG
            color={theme.palette.mode === "dark" ? "#f6f6f6" : "#1f1d22"}
          />

        </Box>
        
      </Box>

      {/* Products Grid */}

      <Box className={styles.productsContainer}>
        {!products.length && loadingProducts ? (
          <CircularProgress
            size={64}
            color="primary"
            sx={{ marginTop: theme.spacing(5) }}
          />
        ) : (
          <>
            {products.map((product) => (
              <ProductCard 
                key={product._id} 
                product={product} 
                variant={isMobile ? "large" : "small"}
              />
            ))}
          </>
        )}
      </Box>

      <Button
        ref={buttonRef}
        className={styles.button}
        fullWidth
        loading={loadingCategory || loadingProducts}
        onClick={handleNextPage}
      >
        {translate("catalog.button")}{" "}
        <Box sx={{ transform: "rotate(270deg)", ml: "4px" }}>
          <ArrowLeft width={18} height={17} />
        </Box>
      </Button>

      {/* Divider */}
      <Box className={styles.divider} />

      {/* SEO Image + Text */}
      <Box className={styles.appInfo}>
        <Box className={styles.seoInfo}>
          <Card
            className={styles.banner}
            sx={{
              backgroundImage: `url(${
                category?.banner || ""
              })`,
            }}
          />
        </Box>

        <Box className={styles.platformInfoTitleContainer}>
          <CupIcon
            color={theme.palette.mode === "dark" ? "#EDF67D" : "#8142f6"}
          />
          {category?.name && (
            <Typography className={styles.infoTitle}>
              {translate("games.info.title", { title: category?.name })}{" "}
              <span>Mentup</span>
            </Typography>
          )}
        </Box>
        {Array.from({ length: 1 }, () => ({
          description: category?.description 
        })).map(renderInfoContainer)}
      </Box>

      <MentupModal open={openGroup} close={handleCloseModal}
        sx={{
          minWidth: '350px',
          maxWidth: '350px',
          // if media mobile 900px max, then minWidth is 90%
          '@media (max-width: 900px)': {
            minWidth: '80%',
            maxWidth: '80%',
          },
        }}
      >
        {activeGroup && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            
          }}>
          <Box className={styles.modalHeader}>
            <Box>
              <Typography 
                className={styles.modalHeaderReset}
                onClick={() => 
                    {
                      if(activeGroup.groupType !== 'all') {
                        setSelectedAttr(selectedAttr.filter(attr => attr.attributeGroup.slug !== activeGroup.slug))
                      } else {
                        setSelectedAttr([])
                        
                      }
                      setFilters(defaultFilters)
                    }
                }
              >
                {capitalize('Сбросить')}
              </Typography>
            </Box>
            <Typography className={styles.modalHeaderTitle}>
                {activeGroup.groupType  === 'all' ? capitalize(activeType?.name) : capitalize(activeGroup.name)}
            </Typography>
            <IconButton
                onClick={handleCloseModal}
            >
                <CloseIcon sx={{
                    fontSize: '26px',
                    color: 'gray',
                }} />
            </IconButton>
          </Box>
          <Box className={styles.modalContent}>
          <Box className={styles.modalContentItems}>

            {activeGroup.groupType === "choice" &&
              activeGroup?.attributes?.map((attr) => (
                <Box key={attr._id} sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mb: 1,
                  width: '100%'
                }}>
                <Box
                    key={attr._id} // Ensure each mapped element has a unique key
                    sx={{
                      width: '100%',
                      display: "flex",
                      justifyContent: 'space-between',
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                      mb: 1,
                      ':hover':{
                        cursor: 'pointer'
                      }
                    }}
                    onClick={() => handleChoice(attr)}
                  >
                    
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.8,
                    }}
                  >
                    <Typography sx={{ 
                        fontSize: '18px', 
                        fontWeight: 400, 
                        ':first-letter': {
                          textTransform: 'uppercase'
                        }
                        
                        }}>
                      {attr.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Radio
                      size="medium"
                      checked={selectedAttr?.find((a)=> a._id === attr._id)?.value || false}
                    />
                  </Box>
                </Box>
                <Divider sx={{backgroundColor: 'gray', width: '100%', opacity: 0.3}} />
                </Box>
              ))}

            {activeGroup.groupType === "single" &&
              activeGroup?.attributes?.map((attr) => (
                <Chip
                  key={attr._id}
                  className={styles.modalContentItem}
                  label={capitalize(attr.name)}
                  onClick={() => handleAttrClick(attr)}
                  sx={{
                    backgroundColor: selectedAttr?.some((a) => a._id === attr._id)
                      ? "#8142f6 !important"
                      : "",
                    "& span": {
                      color:
                        theme.palette.mode === "light" &&
                        selectedAttr?.some((a) => a._id === attr._id)
                          ? "white !important"
                          : "",
                    },
                  }}
                />
              ))}

            {activeGroup.groupType === "range" &&
              activeGroup?.attributes.map((attr) => (
                <Box key={attr._id} className={styles.modalContentItemRange}>
                  <Box sx={{ paddingX: "20px" }}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '0.5em'
                    }}>
                      <TextField
                        size="small"
                        value={
                          selectedAttr?.find((a) => a._id === attr._id)?.valueRange?.min || ''
                        }
                        onInput={(e) => {
                          // Allow only numeric input
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        onChange={(e) => {
                          const valueRange = attrs?.find((a) => a._id === attr._id && a.slug !== 'price')?.valueRange;
                          const minValue = valueRange?.min || 0;
                          const maxValue = valueRange?.max || Infinity;
                          const inputValue = Number(e.target.value);
                          if (!e.target.value || (inputValue >= minValue && inputValue <= maxValue)) {
                            // Allow clearing input or valid values within range
                            handleInnerRange(attr, inputValue || '', false);
                          } else if (inputValue > maxValue) {
                            // Prevent values above max
                            e.target.value = maxValue.toString();
                          } else {
                            // Prevent values below min
                            e.target.value = minValue.toString();
                          }
                        }}
                        inputProps={{
                          pattern: '[0-9]*',
                          inputMode: 'numeric',
                          min: attrs?.find((a) => a._id === attr._id && a.slug !== 'price')?.valueRange?.min || undefined,
                          max: attrs?.find((a) => a._id === attr._id && a.slug !== 'price')?.valueRange?.max || undefined,
                        }}
                        placeholder={`От ${
                          attrs?.find((a) => a._id === attr._id)?.valueRange?.min || ''
                        }`}
                        {...(attr.slug === 'price' && {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <CurrencyRubleIcon sx={{ fontSize: '18px' }} />
                              </InputAdornment>
                            ),
                          },
                        })}
                      />

                      <RemoveIcon sx={{fontSize: '16px'}} />
                      <TextField
                        size="small"
                        value={selectedAttr?.find((a) => a._id === attr._id)?.valueRange?.max || ''}
                        onInput={(e) => {
                          // Allow only numbers
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        onChange={(e) => {
                          const maxValue = attrs?.find((a) => (a._id === attr._id && a.slug !== 'price'))?.valueRange?.max;
                          const inputValue = Number(e.target.value);

                          if (inputValue <= maxValue || !maxValue) {
                            handleInnerRange(attr, inputValue, true); // Proceed with valid value
                          } else {
                            e.target.value = maxValue; // Prevent exceeding max
                          }
                        }}
                        inputProps={{
                          pattern: '[0-9]*',
                          inputMode: 'numeric',
                          max: attrs?.find((a) => (a._id === attr._id && a.slug !== 'price'))?.valueRange?.max || undefined,
                        }}
                        placeholder={`До ${
                          attr.slug === 'price'
                            ? ''
                            : attrs?.find((a) => a._id === attr._id)?.valueRange?.max
                        }`}
                        {...(attr.slug === 'price' && {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <CurrencyRubleIcon sx={{ fontSize: '18px' }} />
                              </InputAdornment>
                            ),
                          },
                        })}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}

          {activeGroup.groupType === "all" &&
            originalGroups
              .filter((group) => group.groupType === "choice")
              .map((group) => {
                return (
                    <Box
                      key={group._id} // Ensure each mapped element has a unique key
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                        marginBottom: 1, // Optional spacing between groups,
                        
                      }}
                    >
                      <Typography sx={{ fontSize: '14px', opacity: 0.8 }} gutterBottom>
                        {group.name}
                      </Typography>
                      {group.attributes?.map((attr) => {
                        return (
                          <Box
                            key={attr._id} // Ensure each mapped element has a unique key
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              mb: 1,
                              width: '100%',
                              ':hover':{
                              cursor: 'pointer'
                            }
                            }}
                            onClick={() => handleChoice(attr)}
                            
                          >
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                mb: 1,
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.8 }}>
                                <Typography sx={{ 
                                    fontSize: '18px', 
                                    fontWeight: 400, 
                                    ':first-letter': {
                                      textTransform: 'uppercase'
                                    }
                                    
                                    }}>
                                  {attr.name}
                                </Typography>
                              </Box>
                              <Box>
                                <Radio
                                  size="medium"
                                  checked={selectedAttr?.find((a) => a._id === attr._id)?.value || false}
                                />
                              </Box>
                            </Box>
                            <Divider sx={{ backgroundColor: 'gray', width: '100%', opacity: 0.3 }} />
                          </Box>
                        );
                      })}
                    </Box>
                );
              })}

            {activeGroup.groupType === "all" &&
              originalGroups
                .filter((group) => group.groupType === "switch" && (group.slug !== 'discount' && group.slug !== 'reviews'))
                .map((group) => (
                  <Box
                    key={group._id} // Ensure each mapped element has a unique key
                    sx={{
                      width: '100%',
                      display: "flex",
                      justifyContent: 'space-between',
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                      mb: 2,
                    }}
                  >
                    
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.8
                  }}>
                    <Typography>{group.name}</Typography>
                  </Box>
                  <MentupSwitch
                    checked={selectedAttr?.find((a)=> a._id===group._id || a.slug===group.slug)?.value || false}
                    onChange={()=>{
                      handleActiveGroup(group)
                    }}
                  />
                </Box>
              ))}

              {activeGroup.groupType === "all" &&
                originalGroups
                  .filter((group) => group.groupType === "single")
                  .map((group) => (
                    <Box
                      key={group._id} // Ensure each mapped element has a unique key
                      sx={{
                        width: '100%',
                        display: "flex",
                        flexDirection: "column",
                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                        marginBottom: 1, // Optional spacing between groups
                      }}
                    >
                      <Typography sx={{fontSize: '14px', opacity: 0.8}} gutterBottom>
                        {group.name}
                      </Typography>
                      <Box className={styles.modalContentItems}>
                      {group.attributes.map((attr) => (
                        <Chip
                          key={attr._id}
                          className={styles.modalContentItem}
                          label={capitalize(attr.name)}
                          onClick={() => handleAttrClick(attr)}
                          sx={{
                            backgroundColor: selectedAttr?.some((a) => a._id === attr._id)
                              ? "#8142f6 !important"
                              : "",
                            "& span": {
                              color:
                                theme.palette.mode === "light" &&
                                selectedAttr?.some((a) => a._id === attr._id)
                                  ? "white !important"
                                  : "",
                            },
                          }}
                        />
                      ))}
                      </Box>
                    </Box>
              ))}

        {activeGroup.groupType === "all" &&
          originalGroups
            .filter((group) => group.groupType === "range")
            .map((group) => (
              <Box
                key={group._id} // Ensure each mapped element has a unique key
                sx={{
                  width: '100%',
                  display: "flex",
                  flexDirection: "column",
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                  marginBottom: 1, // Optional spacing between groups
                }}
              >
                <Typography sx={{fontSize: '14px', opacity: 0.8}} gutterBottom>
                  {capitalize(group.name)}
                </Typography>
                <Box className={styles.modalContentItems}>
                  {group.attributes.map((attr) => (
                    <Box key={attr._id} sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1em'
                    }}>
                      <TextField
                        size="small"
                        value={
                          selectedAttr?.find((a) => a._id === attr._id)?.valueRange?.min || ''
                        }
                        onInput={(e) => {
                          // Allow only numeric input
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        onChange={(e) => {
                          const valueRange = attrs?.find((a) => a._id === attr._id && a.slug !== 'price')?.valueRange;
                          const minValue = valueRange?.min || 0;
                          const maxValue = valueRange?.max || Infinity;
                          const inputValue = Number(e.target.value);

                          if (!e.target.value || (inputValue >= minValue && inputValue <= maxValue)) {
                            // Allow clearing input or valid values within range
                            handleInnerRange(attr, inputValue || '', false);
                          } else if (inputValue > maxValue) {
                            // Prevent values above max
                            e.target.value = maxValue.toString();
                          } else {
                            // Prevent values below min
                            e.target.value = minValue.toString();
                          }
                        }}
                        inputProps={{
                          pattern: '[0-9]*',
                          inputMode: 'numeric',
                          min: attrs?.find((a) => a._id === attr._id && a.slug !== 'price')?.valueRange?.min || undefined,
                          max: attrs?.find((a) => a._id === attr._id && a.slug !== 'price')?.valueRange?.max || undefined,
                        }}
                        placeholder={`От ${
                          attrs?.find((a) => a._id === attr._id)?.valueRange?.min || ''
                        }`}
                        {...(attr.slug === 'price' && {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <CurrencyRubleIcon sx={{ fontSize: '18px' }} />
                              </InputAdornment>
                            ),
                          },
                        })}
                      />

                      <RemoveIcon sx={{fontSize: '16px'}} />
                      <TextField
                        size="small"
                        value={selectedAttr?.find((a) => a._id === attr._id)?.valueRange?.max || ''}
                        onInput={(e) => {
                          // Allow only numbers
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        onChange={(e) => {
                          const maxValue = attrs?.find((a) => (a._id === attr._id && a.slug !== 'price'))?.valueRange?.max;
                          const inputValue = Number(e.target.value);

                          if (inputValue <= maxValue || !maxValue) {
                            handleInnerRange(attr, inputValue, true); // Proceed with valid value
                          } else {
                            e.target.value = maxValue; // Prevent exceeding max
                          }
                        }}
                        inputProps={{
                          pattern: '[0-9]*',
                          inputMode: 'numeric',
                          max: attrs?.find((a) => (a._id === attr._id && a.slug !== 'price'))?.valueRange?.max || undefined,
                        }}
                        placeholder={`До ${
                          attr.slug === 'price'
                            ? ''
                            : attrs?.find((a) => a._id === attr._id)?.valueRange?.max
                        }`}
                        {...(attr.slug === 'price' && {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <CurrencyRubleIcon sx={{ fontSize: '18px' }} />
                              </InputAdornment>
                            ),
                          },
                        })}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}

          {activeGroup.groupType === "all" &&
            originalGroups
              .filter((group) => group.groupType === "switch" && (group.slug === 'discount' || group.slug === 'reviews'))
              .map((group) => (
                <Box
                key={group._id} // Ensure each mapped element has a unique key
                sx={{
                  width: '100%',
                  display: "flex",
                  justifyContent: 'space-between',
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                  mt: 2,
                }}
              >
                
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.8
                }}>
                  {group.slug === 'discount' ? (
                    <Box
                      sx={{
                        background: (theme) => theme.palette.mode !== 'dark' ? theme.palette.primary.searchbg : theme.palette.primary.yellow,
                        display: 'flex',
                        alignItems: 'center',
                        py: 0.5,
                        px: 0.7,
                        borderRadius: '7px',
                        color: theme.palette.mode !== 'dark' ? "white" : 'black'
                      }}
                      >
                      <Typography sx={{
                        height: 'fit-content',
                        lineHeight: 0,
                        fontSize: '1.2em',
                      }}>
                        -
                      </Typography>
                      <PercentIcon 
                        sx={{
                          fontSize: '1em',
                          verticalAlign: 'middle',
                        }} 
                      />
                    </Box>
                  ) : (
                    <>
                    <StarIcon 
                        sx={{
                          fontSize: '1.2em',
                          verticalAlign: 'middle',
                          color: "primary.main",
                        }}    
                    />
                    </>
                  )}
                  <Typography sx={{fontSize: '18px'}}>
                    {group.slug === 'discount'
                      ? 'По Скидке' : 'С отзывами'
                    }
                  </Typography>
                </Box>
                <MentupSwitch
                  checked={selectedAttr?.find((a)=> a._id===group._id)?.value || false}
                  onChange={()=>{
                    handleActiveGroup(group)
                  }}
                />
              </Box>
            ))}

          </Box>
          </Box>
          <LoadingButton
            className={styles.button2}
            onClick={() => {
              handleActiveAttrChange(selectedAttr);
              setOpenGroup(false);
            }
          }
          >
              <Typography>Применить</Typography>
          </LoadingButton>
          </Box>
        )}
      </MentupModal>
    </Container>
  );
};

export default CategoryProductPage;
